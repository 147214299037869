
import { defineComponent, computed } from 'vue'
import Logo from '@/components/particles/common/Logo.vue'
import useStore from '@/store'

export default defineComponent({
  components: {
    Logo,
  },

  emits: ['update-component'],
  setup() {
    // const userHiddenPhone = ref<string>('XX XX XX XX 99')
    const store = useStore()
    const userHiddenPhone = computed(
      () => store.state.auth.userInfo.phone_number
    )
    //TO DO: get user phone from the answer on previous step
    return { userHiddenPhone }
  },
})
