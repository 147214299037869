import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-w-[398px] max-h-md w-full pt-10 px-5 pb-8 bg-white rounded-lg" }
const _hoisted_2 = { class: "mb-6 px-7 text-base text-center" }
const _hoisted_3 = { class: "mb-6 text-darkGrey flex justify-center text-base text-center font-medium leading-[10px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createVNode(_component_Logo, { class: "mb-[30px]" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_SENT_SMS_TEXT')), 1),
      _createElementVNode("p", null, "par XX XX XX XX " + _toDisplayString(_ctx.userHiddenPhone), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", {
        class: "cursor-pointer border-b border-b-transparent hover:border-b-darkGrey transition duration-200",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update-component', 'ForgetPasswordEmailCheck')))
      }, _toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_LINK_RESEND_SMS')), 1)
    ]),
    _createVNode(_component_UiButton, {
      class: "w-full py-4 mb-2.5 bg-blue bg-blue hover:bg-darkBlue text-white",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update-component', 'ForgetPasswordSmsCheck'))),
      type: "button"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_SUBMIT_BUTTON')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_UiButton, {
      class: "py-4 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update-component', 'ForgetPasswordEmailCheck'))),
      type: "button"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('FORGET_PASSWORD_FORM_RETURN_BUTTON')), 1)
      ]),
      _: 1
    })
  ]))
}